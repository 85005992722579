import React from 'react';
import Layout from '../../components/layout/Layout';
import PageNavigator from "../../components/layout/PageNavigator";
import ContentAM from '../../components/ContentAM';
import license from '../../data/corsi-patente/patente-C/license.json';
import Seo from '../../components/layout/SEO';
import BannerCompass from '../../components/BannerCompass';


const PatentiC = () => {
  return (
    <Layout>
      <Seo
        title={"Corsi per Patente C1, CE, C | Autoscuola Faro, Pisa"}
        description={"Prendi la patente di categoria C, C1, CE o CE1 con i nostri corsi professionali. Siamo in centro a Pisa."}
        keywords={[
          "Corsi Patente C Pisa",
          "Corsi Patente C1 Pisa",
          "Corsi Patente CE Pisa",
          "Prendere Patente Categoria C Pisa",
          "Prendere Patente Categoria C1 Pisa",
          "Prendere Patente Categoria CE Pisa",
          "Corsi Patente Professionali Pisa",
          "Autoscuola Faro Pisa"
        ]}
      />

      <PageNavigator link="/corsi-patente" text="Corsi patenti" />
      <ContentAM license={license} />
      <BannerCompass/>
    </Layout>
  )
};

export default PatentiC;
